<!-- =========================================================================================
  File Name: DataListThumbView.vue
  Description: Data List - Thumb View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container" v-if="products.length">
    <vx-card title="الفلترة" class="mb-2" title-color="primary">

      <div class="vx-row  align-center">
        <div class="demo-alignment">
          <vs-popup class="holamundo" title="تنزيل المنتجات" :active.sync="popupActiveExport">
            <div class="flex flex-col justify-items-center mb-5">
              <div class="mb-2"><label for="">الملف</label></div>
              <file-upload ref="upload" v-model="file" class="vs-button bg-primary mt-5">
                رفع الملف
              </file-upload>
            </div>
            <vs-button @click="importNewProducts" color="success" type="filled" icon-pack="feather" icon="icon-check">
              تنزيل</vs-button>

          </vs-popup>
        </div>
        <div class="vx-col md:w-1/2 flex">
          <vs-dropdown vs-trigger-click class="md:w-1/2 dd-actions cursor-pointer mr-4 mb-4">
            <div class="
            p-4
            shadow-drop
            rounded-lg
            d-theme-dark-bg
            cursor-pointer
            flex
            items-center
            justify-center
            text-lg
            font-medium
            w-32 w-full
          ">
              <span class="mr-2">البحث ب {{ getSearchByKey(search_by) }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="search_by = 'contains[sku]'">
                <span class="flex items-center">

                  <span>sku المنتج</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="search_by = 'starts_with[id]'">
                <span class="flex items-center">

                  <span>id المنتج</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="search_by = 'starts_with[name]'">
                <span class="flex items-center">

                  <span>اسم المنتج</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-input placeholder="بحث" type="text" class="md:w-3/4" name="note" v-model="searchQuery" />
        </div>
      </div>

      <div class="vx-row  align-center mb-5">
        <div class="vx-col md:w-1/3">
          <div class="mb-2"><label for="">القسم الرئيسي</label></div>
          <v-select label="name" :reduce="(item) => item.id" v-model="category_id" @input="chooseCategory"
            :options="categories" name="main_catgeory" class="w-full"></v-select>
        </div>
        <div class="vx-col md:w-1/3">
          <div class="mb-2"><label for="">القسم الفرعي</label></div>
          <v-select label="name" :reduce="(item) => item.id" v-model="sub_category_id" :options="subcategories"
            name="main_catgeory" class="w-full"></v-select>
        </div>
        <div class="vx-col md:w-1/3">
          <div class="mb-2"><label for="">العلامة التجارية</label></div>
          <v-select label="name" :reduce="(item) => item.id" v-model="brand_id" :options="brands" name="brands"
            class="w-full"></v-select>
        </div>
      </div>


      <div class="vx-row justify-end">
        <vs-button class="mr-3" color="#7367F0" type="filled" @click="search()">
          بحث
        </vs-button>
        <vs-button class="mr-3" color="warning" type="border" @click="reset()">
          إعادة تعيين
        </vs-button>
      </div>

    </vx-card>

    <vs-table v-if="products.length" ref="table" multiple :sst="true" v-model="selected" :max-items="meta.per_page"
      :data="products">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center">
          <div @click="exportPdf" class="
              btn-add-new
              p-3
              mb-4
              mr-4
              rounded-lg
              cursor-pointer
              flex
              items-center
              justify-center
              text-lg
              font-medium
              text-base text-primary
              border border-solid border-primary
            ">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">تنزيل pdf</span>
          </div>
          <div @click="importExcel" class="
              btn-add-new
              p-3
              mb-4
              mr-4
              rounded-lg
              cursor-pointer
              flex
              items-center
              justify-center
              text-lg
              font-medium
              text-base text-primary
              border border-solid border-primary
            ">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">رفع الاسعار الجديدة</span>
          </div>
          <vs-button @click="exportProducts" color="primary" class="mr-2" type="filled" icon-pack="feather"
            icon="icon-download">
            تنزيل المنتجات</vs-button>
        </div>

      </div>

      <template slot="thead">
        <vs-th sort-key="sku">#id</vs-th>
        <vs-th sort-key="sku">رقم SKU</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="price">السعر</vs-th>

        <vs-th sort-key="new_price">السعر بعد التخفيض</vs-th>
        <vs-th sort-key="new_price">سعر التكلفة</vs-th>

        <vs-th sort-key="quantity">الكمية</vs-th>
        <vs-th sort-key="quantity">percentage</vs-th>
        <vs-th sort-key="quantity">مرئي</vs-th>


        <vs-th sort-key="updated_at">تم التعديل في</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.id }}#</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.sku }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.name }}</p>
            </vs-td>
            <vs-td>
              <vs-input type="number" class="w-1-2" label-placeholder="السعر" v-model="tr.price"
                @blur="updateProduct(tr)" />
            </vs-td>
            <vs-td>
              <vs-input type="number" class="w-1-2" label-placeholder="السعر بعد التخفيض" v-model="tr.new_price"
                @blur="updateProduct(tr)" />
            </vs-td>
            <vs-td>
              <vs-input type="number" class="w-1-2" label-placeholder="سعر التكلفة" v-model="tr.original_price"
                @blur="updateProduct(tr)" />
            </vs-td>
            <vs-td>
              <vs-input type="number" class="w-1-2" label-placeholder="الكمية" v-model="tr.quantity"
                @blur="updateProduct(tr)" />
            </vs-td>
            <vs-td>
              <vs-input type="number" class="w-1-2" label-placeholder="percentage" v-model="tr.percentage"
                @blur="updateProduct(tr)" />
            </vs-td>
            <vs-td>
              <vs-switch v-model="tr.is_visible" @input="updateProduct(tr)"></vs-switch>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ formatDate(tr.updated_at) }}
              </p>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination class="mt-4" :total="meta.last_page" v-model="current_Page" @change="getData(current_Page)">
    </vs-pagination>
    <div style="display: none" id="table_body">
      <table id="table_products_pdf" style="width: 100%">
        <thead id="table_products_pdf_header" style="background-color: black">
          <tr id="table_products_pdf_header_row"></tr>
        </thead>
        <tbody id="table_body">
          <tr tyle="width:100%" v-for="(product, index) in allProducts" :key="index">
            <td style="text-align: center" v-for="(value, key) in product" :key="key">
              <span style="color: black; font-weight: bold" v-if="key !== 'image'">{{ value }}</span>
              <img style="width: 40px; text-align: center; margin: 5px" v-if="key == 'image'" :src="value" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import font from "./font";
import { Base64 } from "js-base64";
import jsPDF from "jspdf";

import VueUploadComponent from 'vue-upload-component'
import vSelect from "vue-select";
import "jspdf-autotable";
import { mapActions } from "vuex";
import moduleProduct from "@/store/products/moduleProduct.js";
export default {
  components: {
    vSelect,
    'file-upload': VueUploadComponent
  },
  data() {
    return {
      selected: [],
      current_Page: 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      products: [],
      meta: {},
      allProducts: [],
      category_id: '',
      searchQuery: '',
      params: {},
      popupActiveExport: false,
      file: [],
      search_by: 'contains[sku]',
      brand_id: '',
      url: process.env.VUE_APP_BASE_URL,
      subcategories: [],
      sub_category_id: ''
    };
  },
  computed: {
    categories() {
      return this.$store.state.product.Categories;
    },
    brands() {
      return this.$store.state.product.brands;
    },
  },
  methods: {
    ...mapActions("product", ["fetchProducts", 'importUpdatedProducts']),
    chooseCategory() {
      this.categories.map(item => {
        if (item.id == this.category_id) {
          this.subcategories = item.sub_categories;
        }
      })
    },
    getSearchByKey(value) {
      if (value == 'contains[sku]') return 'sku المنتج';
      if (value == 'starts_with[name]') return 'اسم المنتج';
      if (value == 'starts_with[id]') return 'id المنتج';
    },
    reset() {
      this.params = {}
      this.getData(1)
    },
    search() {
      this.params[this.search_by] = this.searchQuery
      this.params['exact[category_id]'] = this.category_id
      this.params['exact[brand_id]'] = this.brand_id
      this.params['exact[sub_category_id]'] = this.sub_category_id
      this.getData(1)
    },
    importExcel() {
      this.popupActiveExport = true
    },
    exportProducts() {
      this.params['is_archived'] = 0;
      this.params['type'] = 'names';
      window.location = `${this.url}/api/products/export${this.toUrlQuery(this.params)}`;
    },
    toUrlQuery(params) {
      return `?${Object.keys(params).map(function (key) {
        return `${key}=${params[key]}`
      }).join('&')}`;

    },
    async exportPdf() {
      await this.$store.dispatch("getAllProducts").then((response) => {
        let sorted = {};
        response.data.map((item) => {
          sorted[item.category.name]
            ? sorted[item.category.name].push({
              sku: item.sku,
              price: item.price,
              new_price: item.new_price,
              quantity: item.quantity,
              availability: item.is_visible ? "Yes" : "No",
              category: item.category.name,
              image: item.media[0].path,
            })
            : (sorted[item.category.name] = [
              {
                sku: item.sku,
                price: item.price,
                new_price: item.new_price,
                quantity: item.quantity,
                availability: item.is_visible ? "Yes" : "No",
                category: item.category.name,
                image: item.media[0].path,
              },
            ]);
        });
        Object.keys(sorted).forEach((type) => {
          this.allProducts = this.allProducts.concat(sorted[type]);
        });
      });
      var columns = [
        "Sku",
        "Price",
        "New Price",
        "Quantity",
        "Availability",
        "Category",
        "Image",
      ];

      let printContents = document.getElementById("table_body");
      printContents.style.display = "table";
      var windosw = window.open();
      windosw.document.body.innerHTML = printContents.innerHTML;
      printContents.style.display = "none";

      let style = document.createElement("style");
      style.innerHTML =
        " @import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap'); body:{ font-family: 'Cairo' } #table_products_pdf_header > tr >th { color: white !important; font-size: 14px; text-align: center; } @page { size: A4; margin: 11mm 17mm 17mm 17mm; } @media print { thead{ -webkit-print-color-adjust: exact; background-color: black; } footer { position: fixed; bottom: 0; } #table-header{ position: fixed; top: 0; left: 0; } html, body { width: 210mm; height: 297mm; -webkit-print-color-adjust: exact; } }";
      windosw.document.body.style.fontFamily = "Cairo";
      windosw.document.head.appendChild(style);

      columns.forEach((h) => {
        let th = windosw.document.createElement("th");
        th.innerText = h;
        windosw.document
          .getElementById("table_products_pdf_header_row")
          .appendChild(th);
      });

      setTimeout(() => {
        windosw.print();
      }, 500);
    },
    importNewProducts() {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("file", this.file[0].file);
      this.importUpdatedProducts(bodyFormData)
        .then((response) => {
          this.popupActiveExport = false
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تعديل المنتج بنجاح",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getData(page) {
      this.params["page"] = page;
      this.fetchProducts(this.params)
        .then((response) => {
          this.products = response.data.products.data;
          this.meta = response.data.products.meta;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    updateProduct(tr) {
      var bodyFormData = new FormData();
      bodyFormData.set("id", tr.id);
      bodyFormData.set("price", tr.price);
      bodyFormData.set("new_price", tr.new_price);
      bodyFormData.set("original_price", tr.original_price);
      bodyFormData.set("percentage", tr.percentage);
      bodyFormData.set("is_visible", tr.is_visible ? 1 : 0);
      bodyFormData.set("quantity", tr.quantity);

      this.$store
        .dispatch("product/updatePriceAndQuantity", bodyFormData)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تعديل المنتج بنجاح",
            color: "success",
          });
          this.getData(this.current_Page);
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },
  },
  mounted() {
    this.$vs.loading();
    this.isMounted = true;
  },
  created() {
    if (!moduleProduct.isRegistered) {
      this.$store.registerModule("product", moduleProduct);
      moduleProduct.isRegistered = true;
    }
    this.$store.dispatch("product/fetchCategories");
    this.$store.dispatch("product/fetchBrands");
    this.getData(1);
  },
};
</script>

<style lang="scss">
#table_products_pdf_header>tr>th {
  color: white !important;
  font-size: 14px;
  text-align: center;
}

@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}

@media print {
  thead {
    -webkit-print-color-adjust: exact;
    background-color: black;
  }

  footer {
    position: fixed;
    bottom: 0;
  }

  #table-header {
    position: fixed;
    top: 0;
    left: 0;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
    -webkit-print-color-adjust: exact;
  }
}

#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
